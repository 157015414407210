@charset "UTF-8";
/* =============================================================================


     index


============================================================================= */
/* =============================================================================


     common


============================================================================= */
.about-btn,
.biz-btn {
  margin-top: 40px;
}

.biz,
.work,
.about {
  padding: 80px 0;
  overflow: hidden;
  position: relative;
}

@media (max-width: 767px) {
  .biz,
  .work,
  .about {
    padding: 40px 0;
  }
}

.index-ttl {
  color: #9cd4cc;
  font-size: 7rem;
  font-family: Avenir, acumin-pro, sans-serif;
  position: relative;
  padding: 0.5em 0;
  line-height: 1;
  display: inline-block;
  letter-spacing: 8px;
}

.index-ttl:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #000;
  height: 100%;
  z-index: -1;
  width: calc(100vw + 4em);
  z-index: -1;
  right: -4em;
}

@media (max-width: 767px) {
  .index-ttl:before {
    width: calc(100% + 16px);
    left: -16px;
    right: 0;
  }
}

.index-ttl span {
  color: #fff;
  font-family: bebas-neue, sans-serif;
  font-size: 2.2rem;
  display: block;
  margin-top: 0.5em;
}

.index-ttl._about {
  padding-right: 0.5em;
}

.index-ttl._biz {
  padding-left: 24px;
  z-index: 1;
}

.index-ttl._biz:before {
  left: 0;
}

.index-ttl._work {
  display: block;
  text-align: center;
}

.index-ttl._work:before {
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
}

@media (max-width: 767px) {
  .index-ttl {
    font-size: 3.8rem;
    letter-spacing: 4px;
    display: block;
  }
  .index-ttl span {
    font-size: 1.8rem;
  }
}

/* =============================================================================


     mv


============================================================================= */
.mv {
  height: 800px;
  position: relative;
  background-color: #ddd;
  background-image: url(/../images/index/mv.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 80px;
}

.mv:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

@media (max-width: 767px) {
  .mv {
    height: calc(100vw + 60px);
    margin-bottom: 40px;
  }
}

.mv-ttlbox {
  text-align: center;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.mv-en {
  font-size: 3rem;
  text-shadow: 3px 3px 3px #231815bf;
  font-family: bebas-neue, sans-serif;
}

@media (max-width: 767px) {
  .mv-en {
    font-size: 1.8rem;
  }
}

.mv-ttl {
  font-size: 5.5rem;
  font-family: "ヒラギノ丸ゴ Pro", tbudrgothic-std, sans-serif;
  text-shadow: 3px 3px 3px #231815bf;
  line-height: 1.6;
}

.mv-ttl span {
  display: block;
  font-size: 6rem;
  text-shadow: 3px 3px 3px #231815bf;
  font-family: bebas-neue, sans-serif;
}

@media (max-width: 767px) {
  .mv-ttl {
    font-size: 3rem;
  }
  .mv-ttl span {
    font-size: 4rem;
  }
}

/* =============================================================================


     about


============================================================================= */
.about-container {
  position: relative;
}

.about-container:before {
  background: url(/../images/index/img01.jpg);
  background-repeat: no-repeat;
  background-size: 1120px;
  background-position-x: right;
  position: absolute;
  margin-left: auto;
  width: 1920px;
  height: 100%;
  min-height: 880px;
  content: "";
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 767px) {
  .about-container:before {
    display: none;
  }
}

.about-img {
  display: none;
}

@media (max-width: 767px) {
  .about-img {
    display: block;
    width: 100vw;
    max-width: 500px;
  }
}

.about-txtbox {
  margin-top: 32px;
  background: #fff;
  position: relative;
  z-index: 2;
  padding: 32px 70px 32px 0;
  font-size: 1.8rem;
  max-width: 900px;
  line-height: 2.22222;
  text-align: justify;
}

@media (max-width: 767px) {
  .about-txtbox {
    font-size: 1.4rem;
    padding: 24px;
    padding-left: 0;
    margin-top: -32px;
  }
}

/* =============================================================================


     biz


============================================================================= */
.biz-container {
  width: 40%;
  margin-left: auto;
  min-width: 620px;
}

@media (max-width: 767px) {
  .biz-container {
    width: 100%;
    min-width: unset;
  }
}

.biz-listbox {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
  width: 1920px;
  height: calc(100% + 120px);
}

@media (max-width: 767px) {
  .biz-listbox {
    width: calc(100% + 16px);
    position: relative;
    top: -16px;
    left: -16px;
    transform: none;
    height: auto;
  }
}

.biz-list {
  width: 1280px;
  margin-right: auto;
  height: 100%;
}

@media (max-width: 767px) {
  .biz-list {
    width: 100%;
    height: 300px;
  }
}

.biz-item._road {
  float: right;
  height: 100%;
}

.biz-item {
  width: 50%;
  border: 1px solid #fff;
  position: relative;
  height: 50%;
}

.biz-item .bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit:cover";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.biz-item-btn {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 166px;
  left: 70%;
  color: #fff;
  font-size: 3rem;
  text-align: center;
  font-family: "ヒラギノ丸ゴ Pro", tbudrgothic-std, sans-serif;
}

.biz-item-btn a {
  display: block;
  width: 100%;
  height: 100%;
  transition: 0.2s;
}

.biz-item-btn a:hover {
  opacity: 0.7;
}

.biz-item-btn img {
  display: block;
}

.biz-item-btn._road {
  left: 20%;
}

@media (max-width: 767px) {
  .biz-item-btn._road {
    left: 50%;
  }
}

@media (max-width: 767px) {
  .biz-item-btn {
    max-width: 40%;
    left: 50%;
    font-size: 1.6rem;
  }
}

.biz-txtbox {
  margin-top: 32px;
  background: #fff;
  position: relative;
  z-index: 2;
  padding: 32px 0px 32px 24px;
  font-size: 1.8rem;
  max-width: 900px;
  line-height: 2.22222;
  text-align: justify;
}

@media (max-width: 767px) {
  .biz-txtbox {
    font-size: 1.4rem;
    margin-top: -32px;
  }
}

/* =============================================================================


     work


============================================================================= */
.work-list {
  margin-top: 60px;
  display: flex;
  justify-content: flex-start;
}

@media (max-width: 767px) {
  .work-list {
    flex-direction: column;
    margin-top: 0px;
  }
}

.work-item {
  width: calc(33.3333333% - (100px / 3));
}

.work-item + li {
  margin-left: 50px;
}

@media (max-width: 767px) {
  .work-item {
    width: 80%;
    max-width: 400px;
    margin: 40px auto 0;
  }
  .work-item + li {
    margin-left: auto;
  }
}

.work-item-img {
  position: relative;
  width: 100%;
  /*   a {
    width: 100%;
    height: 100%;
    background: #cbcbcb;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.2s;

    &:hover {
      opacity: 0.7;
    }
  } */
}

.work-item-img:before {
  content: "";
  display: block;
  padding-top: 100%;
  /* 高さを幅の75%に固定 */
}

.work-item-img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit:cover";
}

.work-item-txt {
  margin-top: 24px;
  font-size: 1.8rem;
}

@media (max-width: 767px) {
  .work-item-txt {
    margin-top: 16px;
    font-size: 1.4rem;
  }
}

.work-btn {
  margin: 80px auto 0;
  text-align: center;
}

@media (max-width: 767px) {
  .work-btn {
    margin-top: 40px;
  }
}
